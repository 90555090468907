<template>
  <article class="lesson-block" v-for="(section, i) in structure" :key="section.id ?? section.key">
    <div class="lesson-block-index">{{ (i + 1).toString().padStart(2, '0') }}</div>
    <div class="lesson-block-body">
      <h3 type="text" class="text-h3 uppercase text-light mb-4 font-bold">{{ section.name }}</h3>
      <draggable
        v-model="section.lessons"
        :group="{ name: 'lessons' }"
        item-key="id"
        @start="drag = true"
        @end="drag = false"
        @change="save($event, section.id)"
        class="mb-4 space-y-2 min-h-10">
        <template #item="{element}">
          <div class="flex items-center gap-4">
            <NuxtLink
              :to="{name: `${element.type}-edit`, params: { sectionId: section.id, ...(element.type === 'test' ? { testId: element.id } : { lessonId: element.id }) } }"
              class="line-card flex-1">
              {{ element.type === 'test' ? 'Тест. ' : '' }}{{ element.name }}
            </NuxtLink>
          </div>
        </template>
      </draggable>
      <div class="flex gap-4 mb-4 actions">
        <NuxtLink :to="{name: 'lesson-edit', params: {sectionId: section.id}}" class="btn btn-primary btn-fullwidth">
          Добавить урок
        </NuxtLink>
        <NuxtLink :to="{name: 'test-edit', params: {sectionId: section.id}}" class="btn btn-primary btn-fullwidth">
          Добавить тест
        </NuxtLink>
        <NuxtLink :to="{name: 'homework-edit', params: {sectionId: section.id}}" class="btn btn-primary btn-fullwidth">
          Добавить д/з
        </NuxtLink>
      </div>
      <div class="flex gap-2 flex-wrap">
        <button class="btn btn-transparent text-system-m"
                v-if="!isFirst(section)" @click="up(section)"><span class="icon-arrow-up"></span></button>
        <button class="btn btn-transparent text-system-m"
                v-if="!isLast(section)" @click="down(section)"><span class="icon-arrow-down"></span></button>
        <button class="btn btn-transparent w-auto px-2 text-system-m"
                v-if="structure.length > 1" @click="confirmRemove(section)">Удалить
        </button>
<!--        <button class="btn btn-transparent w-auto px-2 text-system-m"
                @click="copy(section)">Копировать
        </button>-->
        <NuxtLink :to="{name: 'section-edit', params: {sectionId: section.id}}"
                    class="btn btn-transparent w-auto px-2 text-system-m">
          Редактировать
        </NuxtLink>
      </div>
    </div>
    <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
  </article>
</template>

<script setup>
import useStructure from '~/composables/useStructure'
import { changeLessonOrder, changeSectionOrder, deleteSection } from '@/api/materials'
import draggable from 'vuedraggable'
import PromptModal from '@/components/common/PromptModal'

const props = defineProps(['sections'])

const structure = computed(() => props.sections ?? [])

const { isFirst, isLast, remove, copy } = useStructure(structure)

const updateData = inject('updateData')

const changeOrder = order => async (el) => {
  await changeSectionOrder(el.id, order)
  await updateData()
}

const drag = ref(false)
watch(drag, newValue => {
  if (process.client) document.querySelector('.header').style.pointerEvents = newValue ? 'none' : ''
})

const save = async (event, targetSectionId) => {
  const action = event.added ?? event.moved
  if (!action) return
  const { element: { id }, newIndex } = action
  await changeLessonOrder(id, newIndex, targetSectionId)
  await updateData()
}

const prompt = ref(() => {})
const confirmRemove = async elem => {
  const accept = await prompt.value({
    title: `Удаление секции: ${elem.name}`,
    description: 'Вы уверены, что хотите удалить данную секцию? Данные будет невозможно восстановить. Также будут удалены уроки:',
    items: elem.lessons.map(el => el.name)
  })
  if (accept) {
    remove(elem)
    await deleteSection(elem.id)
  }
}

const up = changeOrder(-1)
const down = changeOrder(1)
</script>

<style scoped lang="scss">
.lesson-block {
  margin-bottom: 20px;
}

.actions {
  @media (max-width: 730px) {
    flex-direction: column;
  }
}

.lesson-block-body {
  & h3 {
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
}
</style>
