<template>
  <main>
    <div class="horiz-container">
      <div class="my-materials-heading">
        <h1 class="page-title">Мои материалы</h1>
        <div class="my-materials-actions">
          <BaseButton :to="{ name: 'section-edit' }" :color-primary="true"> + Создать секцию </BaseButton>
        </div>
      </div>
      <section class="lesson-list mb-4 materials-styles">
        <MaterialsSections v-model:sections="sections"/>
        <div class="lesson-list-empty" v-if="sections.length === 0">
          Создавайте материалы и делитись ими со своими менти.
        </div>
      </section>
    </div>
  </main>
</template>

<script setup>
import MaterialsSections from '@/components/mentor/MaterialsSections'
import { getSections } from '@/api/materials'
import BaseButton from '@/components/common/BaseButton'

useHead({
  title: 'Мои материалы'
})

const { data: sections, refresh } = await useAsyncData(getSections, { transform: data => data.sort((a, b) => a.order - b.order) })

provide('updateData', refresh)
</script>

<style lang="scss">
@import "@/assets/css/materials";

.my-materials-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 75px;
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }
}

.my-materials-actions {
  display: flex;
}
.lesson-list-empty {
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.5em;
  font-weight: bold;
  opacity: 0.4;
  margin-top: 100px;
}
</style>
